export const showBuyBox = (pathname, showJtpBuyBox) =>
  (showJtpBuyBox && !pathname.match('/trading')) ||
  pathname.match('/aptitude-tests')

export const showResumeWidget = (pathname, showJtpBuyBox) =>
  !pathname.match('trading') &&
  !pathname.match('/aptitude-tests') &&
  !showJtpBuyBox

export const showJobTestPrep = (pathname) =>
  !pathname.match('/aptitude-tests/test-types/shl-tests') &&
  !pathname.match('/aptitude-tests/test-providers/practice-shl-tests') &&
  !pathname.match('/aptitude-tests/test-types/thomas-gia-test')

export const getTestUrl = (testUrl) =>
testUrl && (testUrl?.includes('testhq') || testUrl?.includes('shl-test')) ? 'TestHQ' : 'JobTestPrep'
